var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alpha"},[_vm._m(0),_c('div',{staticClass:"section-alpha"},[_c('div',{staticClass:"third-section top"},[_c('p',{staticClass:"text-1"},[_vm._v("Silver")]),_vm._m(1),_c('p',{staticClass:"text-3"},[_vm._v("4 Months")]),_c('div',{staticClass:"btn",on:{"click":_vm.onPostClick}},[_c('p',[_vm._v("Invest Now")])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)]),_c('div',{staticClass:"third-section top"},[_c('p',{staticClass:"text-1"},[_vm._v("Gold")]),_vm._m(7),_c('p',{staticClass:"text-3"},[_vm._v("8 Months")]),_c('div',{staticClass:"btn",on:{"click":_vm.onPostClick}},[_c('p',[_vm._v("Invest Now")])]),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12)]),_c('div',{staticClass:"third-section top"},[_c('p',{staticClass:"text-1"},[_vm._v("Diamond")]),_vm._m(13),_c('p',{staticClass:"text-3"},[_vm._v("12 Months")]),_c('div',{staticClass:"btn",on:{"click":_vm.onPostClick}},[_c('p',[_vm._v("Invest Now")])]),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18)]),_c('div',{staticClass:"third-section top"},[_c('p',{staticClass:"text-1"},[_vm._v("Platinum")]),_vm._m(19),_c('p',{staticClass:"text-3"},[_vm._v("24 Months")]),_c('div',{staticClass:"btn",on:{"click":_vm.onPostClick}},[_c('p',[_vm._v("Invest Now")])]),_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23),_vm._m(24)])]),_vm._m(25),_c('br'),_c('br')])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-alpha-1"},[_c('p',{staticClass:"section-header-1"},[_vm._v("Choose an Investment Plan")]),_c('p',{staticClass:"section-header"},[_vm._v("You don't have an account yet? start trading with Early Wealth.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-2"},[_vm._v("10"),_c('span',{staticClass:"text-2-inner"},[_vm._v("%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("$5,000 MINIMUM DEPOSIT")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("ENHANCED SECURITY")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("1.2% DAILY")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("GUARANTEED ROI")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("24/7 SUPPORT")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-2"},[_vm._v("20"),_c('span',{staticClass:"text-2-inner"},[_vm._v("%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("$25,000 MINIMUM DEPOSIT")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("ENHANCED SECURITY")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("1.4% DAILY")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("GUARANTEED ROI")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("24/7 SUPPORT")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-2"},[_vm._v("30"),_c('span',{staticClass:"text-2-inner"},[_vm._v("%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("$100,000 MINIMUM DEPOSIT")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("ENHANCED SECURITY")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("1.6% DAILY")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("GUARANTEED ROI")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("24/7 SUPPORT")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-2"},[_vm._v("50"),_c('span',{staticClass:"text-2-inner"},[_vm._v("%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("$250,000 MINIMUM DEPOSIT")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("ENHANCED SECURITY")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("1.8% DAILY")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("GUARANTEED ROI")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"separate"},[_c('p',[_vm._v("24/7 SUPPORT")]),_c('i',{staticClass:"bx bxs-info-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-1"},[_c('p',{staticClass:"header"},[_vm._v("General FAQs")]),_c('section',[_c('details',[_c('summary',[_vm._v(" WHAT IS EARLY WEALTH ")]),_c('p',[_vm._v(" Early Wealth is an officially registered company and conducts business under the laws of the UK Government. The company is involved in Stocks, options CFD , Forex and crypto. Also trading other valuable commodities/assets which we offer to our clients to earn from using a single trading account. ")])]),_c('details',[_c('summary',[_vm._v(" HOW SAFE ARE MY INVESTMENTS IN YOUR COMPANY ")]),_c('p',[_vm._v(" It is absolutely safe because all our technical experts are extremely experienced professionals; our Stocks, options CFD , Forex and crypto sourcing and other investment strategies are proven to work. ")])]),_c('details',[_c('summary',[_vm._v(" HOW CAN I MAKE SURE YOUR COMPANY IS A REAL AND NOT A FAKE ONE ")]),_c('p',[_vm._v(" Early Wealth is officially incorporated with the UK Companies Office. verify registration: (click here) ")])]),_c('details',[_c('summary',[_vm._v(" CAN I LOOSE MONEY IF I INVEST NOW? ")]),_c('p',[_vm._v(" In accordance to investment laws of the FCA with over £3Billion investment security, we never worry about those rainy days in global trades and projects as we have our investors covered ")])]),_c('details',[_c('summary',[_vm._v(" DO YOU PROVIDE PROOF OF YOUR TRADING ACTIVITIES ")]),_c('p',[_vm._v(" No, at the moment this is a closed information, but in the future we plan to publish our trade reports regularly. We will announce further, follow our news. ")])])])])
}]

export { render, staticRenderFns }